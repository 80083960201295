<template>
  <div style="position: relative;">
    <h1 style="text-align: center">{{ title }}</h1>
    <el-form class="form" ref="form" label-width="90px"
      style="box-sizing: border-box; overflow: hidden; padding-right: 20px">
      <el-row :gutter="20">
        <el-col :span="12" v-for="(item, index) in printlist" :key="index + item.Keys">
          <el-form-item :label="item.Key" v-if="item.type == '1' && item.Keys !== 'Time'">
            <CustomerSearchSelect v-if="item.Keys == 'CustomerName'" @select="CustomerSelect($event, item)"
              :CompanyId="CompanyId" v-model="item.value" />
            <!-- v-model="entity[item.Keys]" -->
            <el-input v-else v-model="item.value"></el-input>
          </el-form-item>
        </el-col>
        <el-col v-for="(item, index) in printlist" :key="index" :span="24">
          <el-form-item v-if="item.Keys == 'text'" label="">
            <span v-for="(im, ix) in item.value.split('{}')" :key="ix">
              {{ im }}
              <el-input class="dInput" v-if="ix !== item.value.split('{}').length - 1"
                v-model="dynamic['P' + index + 'F' + ix]"></el-input>
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <div>
        <div v-for="(item, index) in tableDataList" :key="item.Id">
        <h2
          style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #ccc;
          margin: 0;
          padding: 0 8px 8px 8px;
        "
        >
          <span>对账明细</span>
          <!-- <el-button icon="el-icon-plus" type="primary" @click="handleAdd(index)"
          >新增</el-button
        > -->
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd(index)"
            >增行</el-button
          >
        </h2>
        <el-table :data="item.datalist" style="width: 100%" :max-height="600">
          <el-table-column
            v-for="(im, ix) in item.columns"
            :key="im.dataIndex"
            :prop="im.dataIndex"
            :label="im.title"
            v-if="im.dataIndex != 'nums'"
          >
            <template slot-scope="scope">
              <div v-if="im.dataIndex == 'Number'">{{ scope.$index + 1 }}</div>
              <el-input
                v-else
                :type="im.type ? im.type : 'text'"
                :maxlength="im.maxlength ? im.maxlength : ''"
                v-model="scope.row[im.dataIndex]"
                @input="
                  im.actionCol == '1'
                    ? handleChange(scope.row, im, scope.$index, ix)
                    : () => { }
                  "
              ></el-input>
            </template>
            <!-- <template slot-scope="scope">
            <el-input
              v-if="im.actionCol == '1'"
              v-model="scope.row[im.dataIndex]"
              @input="handleChange(scope.row[im.dataIndex], scope.row, im)"
              type="number"
              :min="1"
            >
            </el-input>
            <el-input
              v-else-if="im.dataIndex === 'Quantity'"
              v-model="scope.row[im.dataIndex]"
              type="number"
              :min="1"
            >
            </el-input>
            <el-input
              v-else-if="
                im.dataIndex === 'Batch' ||
                (im.dataIndex === 'cInvName' && title == '编辑订单')
              "
              v-model="scope.row[im.dataIndex]"
              type="
                text
              "
            >
            </el-input>
            <span
              v-else-if="
                im.dataIndex === 'cInvName' ||
                im.dataIndex === 'cInvStd' ||
                im.dataIndex === 'Weight' ||
                im.dataIndex === 'Weight1' ||
                im.dataIndex === 'nums'
              "
              >{{ scope.row[im.dataIndex] }}</span
            >
            <el-input v-else v-model="scope.row[im.dataIndex]"></el-input>
          </template> -->
          </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <!-- <i class="el-icon-connection iconStyle"></i> -->
              <el-popconfirm
                title="确定要删除本条货品吗?"
                @confirm="handleDelete(scope, index)"
              >
                <el-button class="warnBtn" slot="reference" type="text"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      </div>
    </el-form>
    <div style="height: 50px"></div>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '5px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 99999,
    }">
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit">保存</el-button>
    </div>
  </div>
</template>

<script>
import op from "@/utils/OperatorCache";
import CustomerSearchSelect from "@/components/CustomerSearchSelect.vue";
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
export default {
  components: {
    CompanySearchSelect,
    CustomerSearchSelect,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      entity: {},
      CustomerSign: "",
      btnLoading: false,
      title: "",
      printlist: [],
      tableDataList: [], // 表格组
      dynamic: {},
      CompanyId: "",
      jsoncontent:{},
      newEntity: {},
      prop: [],
    };
  },
  mounted() {
    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }
    if (this.$route.query.prop) {
      console.log(this.$route.query.prop);
      this.prop = this.$route.query.prop.split(",");
    }
    op.updateInfo(() => {
      this.userInfo = op.info;
    });
    this.$http
      .post(
        "/Base/Base_Reconciliation/GetCompanyTemp?CompanyId=" +
        this.$route.query.CompanyId,
        {}
      )
      .then((res) => {
        console.log(res);
        if (res.Success) {
          this.openForm(
            "新增对账函",
            res.Data.Content,
            this.$route.query.CompanyId
          );
        } else {
          this.$message.error(res.Msg);
          setTimeout(() => {
            this.$router.replace("/Reconciliation");
          }, 1000);
        }
      });
  },
  methods: {
    // 公司选择
    CompanySelect(row) {
      console.log(row);
    },
    CustomerSelect(row, item) {
      console.log(row);
      item.value = row.cCusName;
      this.newEntity.CustomerId = row.Id;
    },
    init() {
      this.entity = {};
      this.newEntity = {};
      this.printlist = [];
      this.dynamic = {};
      this.drawer = true;
    },
    openForm(title, content, CompanyId) {
      this.init();
      this.CompanyId = CompanyId;
      this.title = title;
      if (this.title == "新增对账函") {
        this.entity = content;
        this.printlist = JSON.parse(content).printlist;
        this.jsoncontent = { ...JSON.parse(content) };
        this.printlist = this.printlist.sort((a, b) => a.sort - b.sort);
        this.printlist.forEach((item, index) => {
          if (item.Keys == "text") {
            item.value.split("{}").forEach((im, ix) => {
              if (ix !== item.value.split("{}").length - 1) {
                this.$set(this.dynamic, "P" + index + "F" + ix, "");
              }
            });
          } else if (item.Keys == "CustomerName") {
            this.$set(item, "value", this.prop[0]);
          }
        });
        let keyIndex = 0;
        for (const key in this.dynamic) {
          keyIndex++;
          if (!isNaN(Number(this.prop[keyIndex]))) {
            this.prop[keyIndex] = Number(this.prop[keyIndex]).toFixed(4)
          }
          this.$set(this.dynamic, key, this.prop[keyIndex]);
        }
        this.newEntity.cCusName = this.prop[0]
        var arr = JSON.parse(content).printlist;
        this.jsoncontent = { ...JSON.parse(content) };
        var arr2 = arr.filter((item) => {
          return !item.ispringt;
        });
        arr2.sort((a, b) => a.index - b.index);
        arr2.forEach((item) => {
          if (item.Keys === "Company") {
            this.$set(item, "value", TemName);
            this.$set(this.entity, "Company", Company);
          } else {
            this.$set(item, "value", "");
          }
        });
        console.log(arr2, "123456");
        this.formList = arr2;

        this.tableDataList = arr.filter((item) => {
          return item.type === "4";
        });
        this.tableDataList.forEach((item) => {
          item.datalist = [];
        });
      } else {
        this.getDetailList(content);
      }
    },
    handleAdd(index) {
      this.tableDataList[index].datalist.unshift({});
    },
    // 表格行删除
    handleDelete(scope, index) {
      console.log(scope, index);
      this.tableDataList[index].datalist.splice(scope.$index, 1);
      this.$message.success("操作成功");
    },
    getDetailList(id) {
      this.$http
        .post("/Base/Base_Reconciliation/GetRecDetail?Id=" + id)
        .then((res) => {
          console.log(res);
          if (res.Success) {
            this.newEntity = res.Data;
            this.entity = res.Data.Content;
            this.printlist = JSON.parse(res.Data.Content).printlist;
            JSON.parse(res.Data.SpanContent).printlist.forEach((item) => {
              if (item.Keys != "text") {
                let idx = this.printlist.findIndex(
                  (im) => item.Keys == im.Keys
                );
                if (idx !== -1) {
                  this.$set(this.printlist[idx], "value", item.value);
                }
              }
            });
            this.dynamic = JSON.parse(res.Data.SpanValue);
          }
        });
    },
    handleClose(done) {
      done();
    },
    handleSubmit() {
      console.log(this.dynamic);
      // input 拼接赋值
      this.printlist.forEach((item, index) => {
        if (item.Keys == "text") {
          let str = "";
          item.value.split("{}").forEach((im, ix) => {
            if (ix !== item.value.split("{}").length - 1) {
              str +=
                im +
                '<span class="a">' +
                (this.dynamic["P" + index + "F" + ix] || "") +
                "</span>";
            } else {
              str += im;
            }
          });
          item.value = str;
        }
      });
      // 邮箱html模板
      let html = JSON.parse(JSON.stringify(this.printlist))
        .filter((item) => item.type != 3)
        .sort((a, b) => a.sort - b.sort);
      html.forEach((item) => {
        if (item.Keys == "Title") item.value = `<h1>${item.value}</h1>`;
        else if (item.Keys == "text")
          item.value = `<p style="text-indent: 2em;">${item.value}</p>`;
        else if (item.Keys == "CustomerName")
          item.value = `<p>收货方: ${item.value}</p>`;
      });
      let newHtml = html.map((item) => item.value).join("");
      // 赋值
      this.btnLoading = true;
      this.newEntity.Company = this.CompanyId; // 公司
      // JSON表单
      this.jsoncontent.printlist.forEach((item) => {
        // this.formList.forEach((val) => {
        //   // console.log(val, item);
        //   if (item.Id === val.Id) {
        //     item.value = val.value;
        //   }
        // });
        this.tableDataList.forEach((val2) => {
          if (item.Id === val2.Id) {
            item.value = val2.value;
            item.datalist = val2.datalist;
            item.columns = val2.columns;
          }
        });
      });
      this.newEntity.content = JSON.stringify(this.jsoncontent);
      let SpanContent = JSON.parse(this.entity);
      SpanContent.printlist = this.printlist;
      this.newEntity.HtmlContent = `<div>${newHtml}</div>`;
      this.newEntity.SpanContent = JSON.stringify(SpanContent); // 拼接数据
      this.newEntity.SpanValue = JSON.stringify(this.dynamic); // 段落填写数据
      this.$http
        .post("/Base/Base_Reconciliation/SaveData", this.newEntity)
        .then((res) => {
          this.btnLoading = false;
          if (res.Success) {
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.replace("/Reconciliation");
            }, 1000);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.dInput {
  width: 200px;
}
</style>